import React from 'react';
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql } from 'gatsby'

const instantPushArch = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO 
        postTitle="BlueMail's Instant Push Architecture"
        postDescription='BlueMail uses state-of-the-art technologies to keep the security and privacy of our users a top priority. 
        Our unique technological architecture allows us to deliver a safer solution which is providing industry leading email and calendar services for you and your organization.'
        postURL='/push-proxy'
    />
    <div className="container pt-120 pb-100">      
        <div className='grid-wrapper'>
            <div className='col-12'>
                <h3 style={{marginTop:'20px'}}>Instant Push Architecture</h3>
                <p>At BlueMail, the security and privacy of our users is a top priority. We strongly believe all of our users own their information and we never store emails or sell any user data. Our state-of-the-art technological architecture allows us to deliver safer industry-leading email solutions and calendar services for you and your entire organization. 
                </p>

                <p>We invest a lot of effort in developing the best email solutions for individuals and businesses, with a special emphasis on privacy, reliability, efficiency and security. In addition, we always strive to provide a great experience to all of our users. These are just some of the reasons why professionals prefer BlueMail over other email solutions. 
                </p>

                <p>BlueMail for Android, iOS, Windows, Mac and Linux works by connecting directly to your email provider. We believe that transmitting emails directly between BlueMail client and your email provider is the best way to keep your email data secure. Designing BlueMail to work this way was achieved using a unique architectural approach that enables us to provide excellent service 
while protecting your privacy.

                </p>
                <p>In addition, we offer an optional secure push proxy for Apple iOS devices and for Android devices that for example run the Yahoo Mail service. This proxy serves as a secure pipeline between the email server and your BlueMail app. 
                </p>

                <p> In order to reduce battery consumption, Apple iOS devices limit an app’s background connectivity. While this helps to improve battery performance, it prevents BlueMail for iOS from maintaining a direct connection between your device and email server.
                </p>

                <p> Since it’s not possible to keep a direct open connection on your iOS device, BlueMail offers a way to deliver instant push notifications when a new email arrives in your server mailbox. In order to receive this instant push notification, a continuous connection is needed, which we provide through our push proxy. You also have the option to choose other sync modes such as Fetch or Manual, which don’t require the push proxy at any time.
                </p>

                <p> The push proxy is a secure service that delivers notifications from your email service provider (such as Gmail or Exchange) to your device’s platform-specific push service. BlueMail’s push proxy receives email from the server and transmits them to your device’s push service in 1 to 2 seconds. Push emails are automatically and completely deleted after they’re transmitted and are never stored at any time.
                </p>

                <p>The BlueMail push proxy’s sole purpose is to enhance the email experience and deliver instant notifications to your device when a direct connection from the app is not possible. This means we never use the information for any other purpose.
                </p>

                <p> And for added security, the BlueMail app on your devices uses OAuth 2.0 where applicable and has no access to your account password. Instead, it uses a token provided by the email provider to connect to the email server while keeping your information secure.
                </p>    
                <p> If you have any question or comment, feel free to <a className='hovered-link' href ='mailto:privacy@bluemail.me'>keep in touch</a> with our team to learn more about BlueMail’s highly secured solution.
                </p>
            </div>
        </div>
    </div>
    <Footer />
    </Layout>
);

export default instantPushArch;

export const query = graphql`
query InstantPushArchPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
